
/*export const environment = {
    production : true ,
    url : 'https://pruebasmegaflor.ecupyme.ec/Api/public/' ,
    imgBaseUrl : 'https://pruebasmegaflor.ecupyme.ec/Api/public/',
    pdfBaseUrl : 'https://pruebasmegaflor.ecupyme.ec/Api/public/'
  } ;
  */
 /*export const environment = {
    production: true,
    url: 'http://localhost/apimegaflor/public',
    imgBaseUrl: 'http://localhost/apimegaflor/public',  
    pdfBaseUrl: 'http://localhost:4200' 
  };*/

/*  export const environment = {
    production : true ,
    url : 'https://megaflor.ecupyme.ec/Api/public/' ,
    imgBaseUrl : 'https://megaflor.ecupyme.ec/Api/public/',
    pdfBaseUrl : 'https://megaflor.ecupyme.ec/Api/public/'
  } ;
*/  
export const environment = {
  production : true ,
  url : 'https://erpecupyme.com/Api/public/' ,
  imgBaseUrl : 'https://erpecupyme.com/Api/public/',
  pdfBaseUrl : 'https://erpecupyme.com/Api/public/'
} ;
